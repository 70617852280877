import { IFilterAndSort } from './filter-and-sort';
import { DateRangePicker } from 'components/date-range-picker/date-range-picker';
import { SortFilter } from './sort-filter';

export const defaultFilters: IFilterAndSort = {
    search: '',
    status: 'active',
    date: {
        from: DateRangePicker.ranges[DateRangePicker.defaultRangeLabel][0],
        until: DateRangePicker.ranges[DateRangePicker.defaultRangeLabel][1]
    },
    category: '',
    vessel: null,
    channel: '',
    lastAction: '',
    zone: '',
    rule: '',
    ttp: '',
    urgency: [0, 100],
    hasComments: undefined,
    hasAppliedCountermeasures: undefined,
    isPartOfCase: undefined,
    sort: SortFilter.CreatedDesc
};
