import { Disposable } from 'aurelia-binding';
import { AureliaConfiguration } from 'aurelia-configuration';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { RedirectToRoute } from 'aurelia-router';
import { DateRangePicker } from 'components/date-range-picker/date-range-picker';
import { UxBlade } from 'components/layout/ux-blade/ux-blade';
import { MultiRangeSlider } from 'components/multi-range-slider/multi-range-slider';
import moment from 'moment';
import notie from 'notie';
import {
    BusinessMap,
    BusinessMapsApiClient,
    CaseBookLightweightExtended,
    EdrAlertGroup,
    SortableThreatFields,
    ThreatSummary,
    ThreatsApiClient,
    ZonesApiClient,
    SicAlert
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { ArrayUtilities } from 'utilities/array-utilities';
import { LocalStorageHelper, LocalStorageKeys } from 'utilities/local-storage-helper';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { EventKeys } from '../../enums/event-keys';
import { ThreatAction } from '../../enums/threat-action';
import { ThreatFilters } from './threat-filters/threat-filters';
import { IFilterAndSort } from './threat-filters/models/filter-and-sort';
import { FilterNames } from './threat-filters/models/filter-names';
import { defaultFilters } from './threat-filters/models/default-filters';
import { SortFilter } from './threat-filters/models/sort-filter';

@autoinject()
export class ThreatsInbox {
  private params: any;
  private threats: ThreatSummary[] = [];
  private readonly take: number = 100;
  private skip: number = 0;
  private loading: boolean = true;
  private currentThreat: ThreatSummary;
  private mobileLayout: boolean = false;
  private threatSwitched: boolean = true;
  private mediumLayout: boolean = false;
  private blade: UxBlade;
  private threatSizes: Array<'sm' | 'md' | 'lg'> = ['sm', 'md', 'lg'];
  private threatSize: 'sm' | 'md' | 'lg' = 'md';
  private dateRangePicker: DateRangePicker;
  private urgencyRangeSlider: MultiRangeSlider;
  private businessMaps: BusinessMap[];
  private flaggedThreats: SicAlert[];
  private searchElement: HTMLInputElement;
  private threatsListElement: HTMLDivElement;
  private enableBusinessFeatures: boolean = false;
  private zoneNames: string[];

  // KPI's, for display purposes only
  private displayedThreatsCount: number;
  private totalThreatsCount: number;

  // Filters: dropdown/select-list values
  private categories: string[];
  private vessels: BusinessMap[];
  private filteredVessels: BusinessMap[];
  private rules: string[];
  private ttps: string[];

  // Filters: selected items
  private FilterNames: typeof FilterNames = FilterNames;

  private filtersComponent: ThreatFilters;
  private filters: IFilterAndSort;
  private filteringEnabled: boolean = false;

  private defaultUrgency = defaultFilters.urgency;
  private urgencyFilterActive: boolean = false;

  // Threat selection
  private selectedThreats: ThreatSummary[] = [];
  private hasSelectedThreats: boolean = false;
  private showThreatSelectors: boolean = false;
  private selectedThreatsCollectionChangedSubscription: Disposable;
  private selectedThreatsLastChanged: number;

  private disabledThreatIds: string[] = [];
  private disabledThreatIdsCollectionChangedSubscription: Disposable;
  private disabledThreatIdsLastChanged: number;

  private beforeThreatActionSubscription: Subscription;
  private onThreatActionSubscription: Subscription;
  private beforeThreatsActionSubscription: Subscription;
  private onThreatsActionSubscription: Subscription;
  private onThreatCommentAddedSubscription: Subscription;
  private onThreatAddedToCaseSubscription: Subscription;

  constructor(
    private config: AureliaConfiguration,
    private state: StateApi,
    private threatsApi: ThreatsApiClient,
    private businessMapsApi: BusinessMapsApiClient,
    private zonesApi: ZonesApiClient,
    private bindingEngine: BindingEngine,
    private eventAggregator: EventAggregator
  ) {
    // Retrieve the preferred threat size from localStorage, 'md' by default
    this.threatSize = LocalStorageHelper.get<'sm' | 'md' | 'lg'>(LocalStorageKeys.ThreatListSize) || 'md';

    // Subscribe to changes in collections, the default change detector isn't sufficient for listening to changes
    // within arrays.
    this.selectedThreatsCollectionChangedSubscription = bindingEngine.collectionObserver(this.selectedThreats)
      .subscribe(this.selectedThreatsCollectionChanged.bind(this));
    this.disabledThreatIdsCollectionChangedSubscription = bindingEngine.collectionObserver(this.disabledThreatIds)
      .subscribe(this.disabledThreatIdsCollectionChanged.bind(this));
  }

  private selectedThreatsCollectionChanged(splices: string[]): void {
    this.hasSelectedThreats = this.selectedThreats.length > 0;

    // Update the selectedThreatsLastChanged timestamp to provide a property that consumers can listen to for
    // changes in the selectedThreats array.
    this.selectedThreatsLastChanged = Date.now();
  }

  private disabledThreatIdsCollectionChanged(splices: string[]): void {
    // Update the disabledThreatIdsLastChanged timestamp to provide a property that consumers can listen to for
    // changes in the disabledThreatIds array.
    this.disabledThreatIdsLastChanged = Date.now();
  }

  private async canActivate(params: any): Promise<any> {
    // If the params contains a threat_id, retrieve it and refresh the page with its short_id so the URL is linkable
    // to others and not to cause confusion between a threat's threat_id and short_id
    if (params.threat_id) {
      const threat = await this.threatsApi.getById(params.threat_id, this.state.company());

      // Redirect to a new version of this page based on the threat's short id
      return new RedirectToRoute('threats', {id: threat.shortId}, {replace: true});
    }
  }

  private async activate(params: any): Promise<any> {
    this.params = params;

    this.beforeThreatActionSubscription = this.eventAggregator.subscribe(EventKeys.beforeThreatAction, (data) => this.beforeThreatAction(data));
    this.onThreatActionSubscription = this.eventAggregator.subscribe(EventKeys.onThreatAction, (data) => this.onThreatAction(data));
    this.beforeThreatsActionSubscription = this.eventAggregator.subscribe(EventKeys.beforeThreatsAction, (data) => this.beforeThreatsAction(data));
    this.onThreatsActionSubscription = this.eventAggregator.subscribe(EventKeys.onThreatsAction, (data) => this.onThreatsAction(data));
    this.onThreatCommentAddedSubscription = this.eventAggregator.subscribe(EventKeys.onThreatCommentAdded, (data) => this.onThreatCommentAdded(data));
    this.onThreatAddedToCaseSubscription = this.eventAggregator.subscribe(EventKeys.onThreatAddedToCase, (data) => this.onThreatAddToCase(data));
  }

  private async attached(): Promise<void> {
    this.setFiltersFromRouteParams();

    // Configure resize event that determines mobile/desktop layout, preventing duplicate threat details component
    this.configureResizeEvent();

    // Retrieve data to populate the 'Advanced' filters
    const company = this.state.company();
    this.threatsApi.getAllThreatTypes(company)
      .then((threatTypes) => {
        // Map threat types to categories
        const categories = threatTypes.map((t) => t.category);
        // Make list of categories unique as it's expected to have duplicates
        const uniqueCategories = [...new Set(categories)];
        // Sort unique categories alphabetically
        this.categories = uniqueCategories.sort((c1, c2) => c1 < c2 ? -1 : 1);
      });

    this.threatsApi.getAllRules(company)
      .then((rules) => {
        this.rules = ArrayUtilities.unique(rules, (rule) => rule.uuid)
          .map((r) => r.name)
          // Sort alphabetically
          .sort((a, b) => a < b ? -1 : 1);
      });

    this.threatsApi.getAllTtps(company)
      .then((ttps) => {
        this.ttps = ttps
          .map((x) => x.name)
          // Sort alphabetically
          .sort((a, b) => a.localeCompare(b));
      });

    this.businessMapsApi.getAll(company)
      .then((businessMaps) => {
        this.vessels = businessMaps.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredVessels = this.vessels;

        this.enableBusinessFeatures = businessMaps.some((x) => x.productName?.toLowerCase().startsWith('business'));
      });

    this.zonesApi.getAll(company)
      .then((zones) =>
        // Convert the zones to unique zone names, taking `customName` if available, otherwise `name`
        this.zoneNames = ArrayUtilities.unique(
          zones.map((z) => z.customName || z.name)
        )
          // Sort zone names alphabetically
          .sort((a, b) => a < b ? -1 : 1)
      );

    // Only fetch an initial batch of threats when the page isn't loaded with active filters (through URL params)
    if (!this.filtersComponent.hasActiveFilters)
      // Retrieve the initial batch of threats after which the user can scroll to retrieve more
      await this.fetchThreats(0, true, false);

    // Focus the first threat in the list, showing the selection and shows the threat in the details screen
    this.focusFirstThreat();
  }

  private setFiltersFromRouteParams(): void {
    // Use params to prepopulate the filters, which kicks in before 'attached'. Such as:
    // '&category' filters by main category
    if (this.params.category)
      this.filters.category = this.params.category;

    if (this.params.from || this.params.until) {
      this.filters.date.from = isNaN(this.params.from)
        ? DateRangePicker.ranges.Now[0]
        : moment(new Date(Number(this.params.from)));
      this.filters.date.until = isNaN(this.params.until)
        ? DateRangePicker.ranges.Now[1]
        : moment(new Date(Number(this.params.until)));
    }

    if (this.params.vessel)
      this.filters.vessel = this.params.vessel;

    if (this.params.zone)
      this.filters.zone = this.params.zone;

    if (this.params.rule)
      this.filters.rule = this.params.rule;

    if (this.params.channel)
      this.filters.channel = this.params.channel;

    if (this.params.lastAction)
      this.filters.lastAction = this.params.lastAction;

    if (this.params.ttp)
      this.filters.ttp = this.params.ttp;

    if (this.params.search)
      this.filters.search = this.params.search;

    // Id in params is actually a threat shortId, but simplified to `id` in the route/URL
    // Id will just be handled as a search query as that should find the matching threat
    if (this.params.id) {
      this.filters.search = this.params.id;

      // The default status is 'active', but since the threat could be closed and we still want to display it, set
      // the status filter to all
      this.filters.status = 'all';
    }

    if (this.params.status)
      this.filters.status = this.params.status;

    if (this.params.sort)
      switch (this.params.sort) {
        case SortFilter.CreatedAsc:
        case SortFilter.CreatedDesc:
        case SortFilter.UrgencyAsc:
        case SortFilter.UrgencyDesc:
        case SortFilter.UpdatedDesc:
          this.filters.sort = this.params.sort;
          break;
        default:
          this.filters.sort = defaultFilters.sort;
          break;
      }

    if (this.params['has-comments'])
      this.filters.hasComments = JSON.parse(this.params['has-comments']);

    if (this.params['has-applied-countermeasures'])
      this.filters.hasAppliedCountermeasures = JSON.parse(this.params['has-applied-countermeasures']);

    if (this.params['is-part-of-case'])
      this.filters.isPartOfCase = JSON.parse(this.params['is-part-of-case']);

    if (this.params['urgency-from'])
      this.filters.urgency[0] = Number(this.params['urgency-from']);

    if (this.params['urgency-to'])
      this.filters.urgency[1] = Number(this.params['urgency-to']);

    // Finally, set filtering to be enabled, allowing filter events to execute
    this.filteringEnabled = true;
  }

  private async selectStatus(status: 'all' | 'active' | 'ignored' | 'closed' | 'flagged'): Promise<void> {
    if (!this.loading){
      this.clearSelectedThreats();

      this.filters.status = status;
    }
  }

  private async clearFilters(): Promise<void> {
    await this.filtersComponent.clearFilters();
  }

  private async clearFilter(filterName: FilterNames): Promise<void> {
    this.currentThreat = null;

    switch (filterName) {
      case FilterNames.Urgency:
        this.urgencyFilterActive = false;
        if (this.urgencyRangeSlider)
          this.urgencyRangeSlider.reset();
        break;
      case FilterNames.Search:
        this.filters.search = defaultFilters.search;
        break;
    }
  }

  private async filter(filters: IFilterAndSort): Promise<void> {
    if (!this.filteringEnabled)
      return;

    this.filters = filters;

    if (filters.urgency[0] === defaultFilters.urgency[0]
      && filters.urgency[1] === defaultFilters.urgency[1]) {
      // Mark urgency filter as inactive when filter has default urgency filter values
      this.urgencyFilterActive = false;
      this.urgencyRangeSlider.reset();
    } else
      // Mark the urgency filter as active when it does not have the default values
      this.urgencyFilterActive = true;

    await this.resetThreats();
  }

  /**
   * Resets the list of threats by clearing and repopulating it
   */
  private async resetThreats(): Promise<void> {
    // Clear the threats list
    this.threats = [];

    // Set skip back to 0, as we're starting over
    this.skip = 0;

    // Reset previous total counts when filter changes as these numbers are not accurate with a different dataset
    this.totalThreatsCount = undefined;
    this.displayedThreatsCount = 0;

    // Set the current threat to null as it might not be available in a different dataset
    this.currentThreat = null;

    // Re-fetch threats
    await this.fetchThreats(0, true, false);
  }

  /**
   * Handler for the virtual scroll threat list.
   * Triggered when the end of the list is near.
   * @param topIndex Index of the item at the top of the rendered list
   * @param isAtBottom Whether the list has been scrolled to the bottom
   * @param isAtTop Whether the list has been scrolled to the top
   */
  private async fetchThreats(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
    // Only fetch more when scroll position is at the bottom
    if (!isAtBottom) return;

    // When at the end of the list and no more threats are available, short-circuit as there's nothing left to fetch
    if (this.totalThreatsCount === this.threats.length) return;

    this.loading = true;
    // Set default sorting properties
    let sort = SortableThreatFields.Created_at;
    let direction = 'desc';
    switch (this.filters.sort) {
      case SortFilter.CreatedAsc:
        sort = SortableThreatFields.Created_at;
        direction = 'asc';
        break;
      case SortFilter.CreatedDesc:
        sort = SortableThreatFields.Created_at;
        direction = 'desc';
        break;
      case SortFilter.UrgencyAsc:
        sort = SortableThreatFields.Urgency;
        direction = 'asc';
        break;
      case SortFilter.UrgencyDesc:
        sort = SortableThreatFields.Urgency;
        direction = 'desc';
        break;
      case SortFilter.UpdatedDesc:
        sort = SortableThreatFields.Updated_at;
        direction = 'desc';
        break;
      default:
        break;
    }

    // Set dates to filter on
    let from: Date;
    let until: Date;
    if (this.filters.date) {
      // When the date selection is default, ignore the from date, as we want the user to be able to scroll
      // indefinitely into history
      if (DateRangePicker.isRange(this.filters.date, DateRangePicker.defaultRangeLabel))
        from = undefined;
      else
        from = this.filters.date.from.toDate();

      until = this.filters.date.until.toDate();
    }

    const category = this.filters.category || undefined;
    const siteIds = this.filters.vessel
      ? [this.filters.vessel.toString()]
      : undefined;
    const zoneName = this.filters.zone || undefined;
    const ruleName = this.filters.rule || undefined;
    const ttpName = this.filters.ttp || undefined;
    const alertType = undefined;
    const channel = this.filters.channel || undefined;
    const lastAction = this.filters.lastAction || undefined;
    const flagged = this.filters.status === 'flagged' || undefined;
    const search = this.filters.search || undefined;
    const minUrgency = this.filters.urgency ? this.filters.urgency[0] : 0;
    const maxUrgency = this.filters.urgency ? this.filters.urgency[1] : 100;

    let handled: boolean | undefined = undefined;
    let status: string;

    switch (this.filters.status) {
      case 'active':
        status = 'Ongoing';
        handled = false;
        break;
      case 'handled':
        status = 'Ongoing';
        handled = true;
        break;
      case 'ignored':
        status = 'Rejected';
        break;
      case 'closed':
        status = 'Closed';
        break;
      case 'all':
      default:
        break; // Leave status undefined, makes it ignore the status filter
    }

    let threats: ThreatSummary[];

    await this.threatsApi.getAllSummaries(
      this.state.company(),
      search,
      this.take,
      this.skip,
      sort,
      direction,
      from,
      until,
      undefined,
      siteIds,
      status,
      handled,
      alertType,
      category,
      undefined,
      flagged,
      undefined,
      minUrgency,
      maxUrgency,
      zoneName,
      ruleName,
      this.filters.hasComments || undefined,
      undefined,
      this.filters.hasAppliedCountermeasures || undefined,
      undefined,
      this.filters.isPartOfCase || undefined,
      undefined,
      ttpName,
      channel,
      lastAction
    )
      .then((pagedThreats) => {
        threats = pagedThreats.items;
        this.totalThreatsCount = pagedThreats.total;
      })
      .catch((error) => {
        threats = [];
        notie.alert({position: 'bottom', text: `Could not retrieve threats`, type: 'error'});
      });

    // Set the first threat as the current threat if there is no current threat, should only trigger after the first
    // fetch
    if (!this.currentThreat && threats.length > 0)
      this.currentThreat = threats[0];

    this.threats = this.threats.concat(threats);
    this.displayedThreatsCount = this.threats.length;

    this.loading = false;

    this.skip += this.take;
  }

  /**
   * Triggers when the user clicks on a threat in the threat list
   * @param clickedThreat Threat that was clicked
   */
  private setCurrentThreat(clickedThreat: ThreatSummary): void {
    this.threatSwitched = false;
    this.currentThreat = clickedThreat;

    setTimeout(() => this.threatSwitched = true);

    if (this.mobileLayout)
      this.blade.show();
  }

  private configureResizeEvent(): void {
    // The reason we check if the view is in mobile through JS instead of CSS is because we want to be using if.bind
    // to conditionally remove entire parts from the DOM. This cannot be done with css and bootstrap helper classes
    // because those will merely hide the parts from the DOM using display:none, which adds more weight to the page
    // as both will be present in the DOM, whereas only one is (and should) be visible.
    window.onresize = this.onResize;

    // Trigger the onResize event handler once to set the initial value for mobileLayout
    this.onResize();
  }

  /**
   * Event handler that checks whether or not the current width is considered mobile width.
   * Triggered on resizing of the browser window.
   */
  private onResize = (event?: UIEvent): void => {
    this.mobileLayout = window.innerWidth < 992;
    this.mediumLayout = window.innerWidth < 578;
  };

  private beforeThreatAction(data: { threat: SicAlert | EdrAlertGroup, action: ThreatAction, comment?: string }): void {
    const matchingThreat = this.threats.find((t) => t.id === (data.threat instanceof EdrAlertGroup ? data.threat.id : data.threat.uuid));

    if (!matchingThreat)
      return;

    if (data.action === ThreatAction.Close || data.action === ThreatAction.Ignore)
      // As close/ignore actions can take a while to process server-side, the threat is disabled for a period of time
      this.disabledThreatIds.push(matchingThreat.id);
  }

  private onThreatAction(data: {
    threat: SicAlert | EdrAlertGroup,
    action: ThreatAction,
    comment?: string
  }): void {
    const matchingThreat = this.threats.find((t) => t.id === (data.threat instanceof EdrAlertGroup ? data.threat.id : data.threat.uuid));

    // Update the threat's status so the action's result is immediately visible
    if (data.threat instanceof EdrAlertGroup) {
      if (data.action === ThreatAction.Close)
        matchingThreat.status = 'Closed';
      if (data.action === ThreatAction.Ignore)
        matchingThreat.status = 'Rejected';

      const index = this.disabledThreatIds.indexOf(matchingThreat.id);
      if (index > -1)
        this.disabledThreatIds.splice(index, 1);
    } else {
      matchingThreat.status = data.threat.status.name;

      setTimeout(() => {
        // Re-enable the disabled threat after a delay to emulate the server-side processing time
        const index = this.disabledThreatIds.indexOf(matchingThreat.id);
        if (index > -1)
          this.disabledThreatIds.splice(index, 1);
      }, 5000);
    }
  }

  private beforeThreatsAction(data: { threats: ThreatSummary[], action: ThreatAction, comment?: string }): void {
    // As close/ignore actions can take a while to process server-side, the threats are disabled for a period of time
    if (data.action === ThreatAction.Close || data.action === ThreatAction.Ignore)
      this.disabledThreatIds.push(...data.threats.map((t) => t.id));
  }

  private onThreatsAction(data: { threats: ThreatSummary[], action: ThreatAction, comment?: string }): void {
    for (const actionedThreat of data.threats) {
      const matchingThreat = this.threats.find((t) => t.id === actionedThreat.id);

      if (!matchingThreat)
        continue;

      // Update the threat's status so the action's result is immediately visible
      matchingThreat.status = actionedThreat.status;
    }

    setTimeout(() => {
      // Re-enable the disabled threats after a delay to emulate the server-side processing time
      this.disabledThreatIds.splice(0, this.disabledThreatIds.length);
    }, 5000);
  }

  private onThreatCommentAdded(data: { threat: SicAlert, comment: string }): void {
    const matchingThreat = this.threats.find((t) => t.id === data.threat.uuid);

    if (!matchingThreat)
      return;

    matchingThreat.commentsTotal++;
  }

  private onThreatAddToCase(data: { caseBook: CaseBookLightweightExtended, threat: SicAlert }): void {
    const matchingThreat = this.threats.find((t) => t.id === data.threat.uuid);

    if (!matchingThreat)
      return;

    matchingThreat.caseBookIds.push(data.caseBook.id);
  }

  private reduceThreatSize(): void {
    // Empty the threats list, we'll populate it again after the size has changed
    this.threats = [];
    this.clearSelectedThreats();

    const index = this.threatSizes.indexOf(this.threatSize);

    if (index === 0)
      // If the current size's index is 0, set the current size to the last size in the array
      this.threatSize = this.threatSizes[this.threatSizes.length - 1];
    else
      // Set the size to the size before the current index (one step smaller)
      this.threatSize = this.threatSizes[index - 1];

    // Update localStorage with threat size preference
    LocalStorageHelper.set<string>(LocalStorageKeys.ThreatListSize, this.threatSize);

    this.skip = 0;
    this.fetchThreats(0, true, false);
  }

  /**
   * Handles navigation from the search input to the threat list.
   * @param event The KeyboardEvent that triggered this function.
   */
  private searchKeyDownHandler(event: KeyboardEvent): boolean {
    if (event.code === 'ArrowDown' || event.code === 'Tab')
      this.focusFirstThreat();

    return true;
  }

  /**
   * Handles up/down previous/next navigation within the threat list.
   * Pressing the up arrow selects and focuses the previous threat.
   * Pressing the right arrow selects and focuses the next threat.
   * Pressing the down arrow selects and focuses the next threat.
   * Pressing the left arrow selects and focuses the previous threat.
   * Pressing Tab selects and focuses the next threat.
   * Pressing Shift + Tab selects and focuses the previous threat.
   * @param event The KeyboardEvent that triggered this function.
   * @param index The index of the currently focussed threat in the list.
   */
  private threatsListKeyDownHandler(event: KeyboardEvent, index: number): void {
    if (event.code !== 'ArrowUp' &&
      event.code !== 'ArrowRight' &&
      event.code !== 'ArrowDown' &&
      event.code !== 'ArrowLeft' &&
      event.code !== 'Tab'
    ) return;

    // Target element from which the event was fired, this is the currently selected threat element
    const target = event.target as HTMLElement;

    // The next or previous threat model
    let siblingThreat: ThreatSummary;
    // The next or previous threat HTML element
    let siblingElement: HTMLElement;

    if (event.code === 'ArrowRight' || event.code === 'ArrowDown' || (event.shiftKey === false && event.code === 'Tab')) {
      // If the index is equal to the threats array length, we can't go any further as there are no more threats
      if (index === this.threats.length) return;

      siblingThreat = this.threats[index + 1];
      siblingElement = target.nextElementSibling as HTMLElement;
    }

    if (event.code === 'ArrowLeft' || event.code === 'ArrowUp' || (event.shiftKey && event.code === 'Tab')) {
      // If we're at index 0, we can't go 'up' any further in the array, moves focus to the search input
      if (index === 0) {
        this.focusSearch();
        return;
      }

      siblingThreat = this.threats[index - 1];
      siblingElement = target.previousElementSibling as HTMLElement;
    }

    if (siblingThreat)
      // Select the threat, triggering a fetch for the threat details in the right pane
      this.setCurrentThreat(siblingThreat);
    if (siblingElement) {
      // Makes the focused element prefer a centered position in the scroll container, this is especially useful
      // because we're working with a virtual-scroll container that phases out any elements not in the viewport.
      // When moving upward from a center scroll position, the phased out elements will now be rendered again.
      siblingElement.scrollIntoView({block: 'center'});

      // Focuses on the sibling so navigation can continue from that element
      siblingElement.focus();
    }
  }

  private clearSelectedThreats(): void {
    // Clear the array while maintaining the reference so the observer stays connected
    this.selectedThreats.splice(0, this.selectedThreats.length);
  }

  private toggleThreatSelectors(): void {
    this.showThreatSelectors = !this.showThreatSelectors;

    if (!this.showThreatSelectors)
      this.clearSelectedThreats();
  }

  private toggleThreatSelection(threat: ThreatSummary): void {
    if (this.selectedThreats.includes(threat)) {
      const threatIndex = this.selectedThreats.indexOf(threat);
      this.selectedThreats.splice(threatIndex, 1);
    } else
      this.selectedThreats.push(threat);
  }

  private selectAllThreats(checked: boolean): void {
    this.clearSelectedThreats();
    if (checked)
      this.selectedThreats.push(...this.threats);
  }

  private threatClicked(clickedThreat: ThreatSummary, event: MouseEvent): boolean {
    // Do not select the threat if the user clicked on the checkbox
    if ((event.target as HTMLElement).id.startsWith('select-'))
      return true;

    if (this.disabledThreatIds.includes(clickedThreat.id))
      return false;

    if (this.hasSelectedThreats || this.showThreatSelectors)
      this.toggleThreatSelection(clickedThreat);
    else
      this.setCurrentThreat(clickedThreat);
  }

  // Cannot simply be computed from selectedThreats as that wouldn't re-trigger this getter.
  // selectedThreatsLastChanged is used for this as the selectedThreatsCollectionChangedSubscription updates this
  // property when the selectedThreats array changes.
  @computedFrom('selectedThreatsLastChanged')
  get selectedThreatIds(): string[] {
    return this.selectedThreats.map((x) => x.id);
  }

  @computedFrom('disabledThreatIdsLastChanged')
  get disabledThreatIdsGetter(): string[] {
    return this.disabledThreatIds.map((x) => x);
  }

  /**
   * Focuses on the first threat in the threat list
   */
  private focusFirstThreat(): void {
    if (!this.threatsListElement)
      return;

    const firstThreat: HTMLElement = this.threatsListElement.querySelector('.threat');
    if (firstThreat) firstThreat.focus();
  }

  /**
   * Focuses on the search input
   */
  private focusSearch(): void {
    if (this.searchElement) this.searchElement.focus();
  }

  private detached(): void {
    // Removes the previously set onresize event
    window.onresize = null;

    this.selectedThreatsCollectionChangedSubscription.dispose();
    this.disabledThreatIdsCollectionChangedSubscription.dispose();
    this.beforeThreatActionSubscription.dispose();
    this.onThreatActionSubscription.dispose();
    this.beforeThreatsActionSubscription.dispose();
    this.onThreatsActionSubscription.dispose();
    this.onThreatCommentAddedSubscription.dispose();
    this.onThreatAddedToCaseSubscription.dispose();
  }
}

