export enum FilterNames {
    Status = 'Status',
    Date = 'Date',
    Category = 'Category',
    Vessel = 'Vessel',
    Zone = 'Zone',
    Rule = 'Rule',
    Channel = 'Channel',
    LastAction = 'LastAction',
    Ttp = 'Ttp',
    Sort = 'Sort',
    Urgency = 'Urgency',
    HasComments = 'HasComments',
    HasAppliedCountermeasures = 'HasAppliedCountermeasures',
    IsPartOfCase = 'IsPartOfCase',
    Flagged = 'Flagged',
    Search = 'Search'
}
