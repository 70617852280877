import { bindable } from 'aurelia-templating';
import { AlertType, BusinessMap, ThreatsApiClient } from 'services/cyber-api';
import $ from 'jquery';
import { ComboboxOption } from 'components/combobox-option/combobox-option';
import { DateRangePicker } from 'components/date-range-picker/date-range-picker';
import { Combobox } from 'components/combobox/combobox';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { Disposable } from 'aurelia-binding';
import { IFilterAndSort } from './models/filter-and-sort';
import { FilterNames } from './models/filter-names';
import { defaultFilters } from './models/default-filters';
import { IDateRange } from '../../../components/date-range-picker/IDateRange';
import moment from 'moment';
import { SortFilter } from './models/sort-filter';
import { Router } from 'aurelia-router';
import { StateApi } from '../../../services/state-api';

@autoinject()
export class ThreatFilters {
    // Filtering
    // Zones
    @bindable() private zones: string[];
    // Vessels
    @bindable() private vessels: BusinessMap[];
    // Categories
    @bindable() private categories: string[];
    // Rules
    @bindable() private rules: string[];
    // Ttps
    @bindable() private ttps: string[];

    @bindable() private filters: IFilterAndSort = defaultFilters;
    @bindable() private filter: ({ filters }) => void;

    // To allow the use of enums and statics in the view, create properties for them
    private FilterNames: typeof FilterNames = FilterNames;
    private SortFilter: typeof SortFilter = SortFilter;
    private DateRangePicker: typeof DateRangePicker = DateRangePicker;

    private defaultFilters: typeof defaultFilters = defaultFilters;
    private threatFiltersDropdownElement: HTMLDivElement;
    private dropdownElement: HTMLDivElement;
    private dateRangePicker: DateRangePicker;
    private vesselFilter: Combobox;
    private filteredVessels: BusinessMap[];
    private disposables: Disposable[] = [];
    private changeHandlingEnabled: boolean = true;
    private alertTypes: typeof AlertType = AlertType;
    private actions: string[];

    constructor(
        private bindingEngine: BindingEngine,
        private router: Router,
        private state: StateApi,
        private threatsApi: ThreatsApiClient
    ) {
    }

    private bind(): void {
        // Set default filters
        const initialFilters = Object.assign({}, defaultFilters);

        // Separately set the date filter to the default to remove any references
        initialFilters.date = {
            from: moment(defaultFilters.date.from),
            until: moment(defaultFilters.date.until)
        };

        this.filters = initialFilters;
    }

    private async attached(): Promise<void> {
        if (!this.threatFiltersDropdownElement)
            throw new Error('Threat filters dropdown element is not defined');

        // Note: it's important to add the observer *after* initial binding to prevent a lot of duplicate filter
        // operations
        this.observeFilterProperties();

        this.addDropdownEventListeners();

        // Initially, set the vessel filter's value to the selected vessel, if any
        this.setVesselFilterValue();

        // Retrieve the actions
        this.actions = await this.threatsApi.getAllLastActions(this.state.company());

        // As the initial binding is ignored because of the presence the bind lifecycle function, manually call filter,
        // only when a filter is active
        if (this.hasActiveFilters)
            this.runFilter();
    }

    private detached(): void {
        this.disposables.forEach(x => x.dispose());
        this.removeDropdownEventListeners();
    }

    private runFilter(): void {
        if (this.filter)
            this.filter({ filters: this.filters });
    }

    private observeFilterProperties(): void {
        // Observe each of the filter properties for changes so filtering can be executed when values change
        const propertyObservers = Object.keys(defaultFilters).map((filterPropertyName) =>
            this.bindingEngine.expressionObserver(this, `filters.${filterPropertyName}`)
        );
        this.disposables.push(
            ...propertyObservers.map((propertyObserver) =>
                propertyObserver.subscribe(this.filterChanged.bind(this))
            ),
            this.bindingEngine.expressionObserver(this, 'filters.vessel')
                .subscribe(this.vesselChanged.bind(this))
        );
    }

    private addDropdownEventListeners(): void {
        // Prevent the filters Bootstrap dropdown from closing when interacting with the filters inside it
        // Note: the dropdown has to be controlled through jQuery
        const $dropdown = $(this.dropdownElement);
        $dropdown.on('click', (event) => {
            if ($(event.target).closest('.threat-filters-dropdown-toggle').length)
                $dropdown.data('closable', true);
            else
                $dropdown.data('closable', false);
        });

        $dropdown.on('hide.bs.dropdown', (event) => {
            const dateRangePickerIsActive = this.dateRangePicker.isActive();

            const hide = $dropdown.data('closable');

            // Don't hide the filters dropdown when the date range picker is active to prevent clicks within the picker
            // from closing the filters dropdown
            if (dateRangePickerIsActive)
                $dropdown.data('closable', false);
            else
                $dropdown.data('closable', true);

            return hide;
        });
    }

    private removeDropdownEventListeners(): void {
        const $dropdown = $(this.dropdownElement);
        $dropdown.off('click');
        $dropdown.off('hide.bs.dropdown');
    }

    private vesselsChanged(): void {
        this.filteredVessels = this.vessels;
    }

    private filterChanged(newValue: any, previousValue: any): void {
        if (!this.changeHandlingEnabled)
            return;

        // When navigating, the previousValue is the bind function
        const isNavigating = typeof (previousValue) === 'function';
        // Only update URL filter params when not navigating
        if (!isNavigating) {
            this.setUrlFilterParams();

            this.runFilter();
        }
    }

    private filtersChanged(newValue: any, previousValue: any): void {
        // Ignore the change when the previous value was empty, which should only happen on initialisation
        if (!previousValue)
            return;

        this.runFilter();
    }

    private vesselChanged(newValue: string, previousValue: string): void {
        this.setVesselFilterValue();
    }

    private setVesselFilterValue(): void {
        // Update the vessel filter component to display the selected vessel
        this.vesselFilter.setSelectedByValue(this.filters.vessel?.toString());
    }

    // Observe each of the filter properties for changes to determine the active filters state
    @computedFrom(...Object.keys(defaultFilters).map((x) => `filters.${x}`))
    public get hasActiveFilters(): boolean {
        if (this.filters.date.from.valueOf() !== defaultFilters.date.from.valueOf()
            || this.filters.date.until.valueOf() !== defaultFilters.date.until.valueOf()) return true;
        if (this.filters.category !== defaultFilters.category) return true;
        if (this.filters.vessel !== defaultFilters.vessel) return true;
        if (this.filters.channel !== defaultFilters.channel) return true;
        if (this.filters.lastAction !== defaultFilters.lastAction) return true;
        if (this.filters.zone !== defaultFilters.zone) return true;
        if (this.filters.rule !== defaultFilters.rule) return true;
        if (this.filters.ttp !== defaultFilters.ttp) return true;
        if (this.filters.hasComments !== defaultFilters.hasComments) return true;
        if (this.filters.hasAppliedCountermeasures !== defaultFilters.hasAppliedCountermeasures) return true;
        if (this.filters.isPartOfCase !== defaultFilters.isPartOfCase) return true;
        if (this.filters.sort !== defaultFilters.sort) return true;

        return false;
    }

    public async clearFilters(): Promise<void> {
        this.changeHandlingEnabled = false;

        await Promise.all(
            Object.keys(FilterNames)
                .map((x) => this.clearFilter(x as FilterNames))
        );

        this.changeHandlingEnabled = true;
    }

    public async clearFilter(filterName: FilterNames): Promise<void> {
        this.changeHandlingEnabled = false;

        switch (filterName) {
            case FilterNames.Date:
                await this.dateRangePicker.reset();
                this.filters.date = defaultFilters.date;
                break;
            case FilterNames.Category:
                this.filters.category = defaultFilters.category;
                break;
            case FilterNames.Vessel:
                this.filters.vessel = defaultFilters.vessel;
                this.vesselFilter.clear();
                break;
            case FilterNames.Zone:
                this.filters.zone = defaultFilters.zone;
                break;
            case FilterNames.Rule:
                this.filters.rule = defaultFilters.rule;
                break;
            case FilterNames.Channel:
                this.filters.channel = defaultFilters.channel;
                break;
            case FilterNames.LastAction:
                this.filters.lastAction = defaultFilters.lastAction;
                break;
            case FilterNames.Ttp:
                this.filters.ttp = defaultFilters.ttp;
                break;
            case FilterNames.Urgency:
                this.filters.urgency = defaultFilters.urgency;
                break;
            case FilterNames.HasComments:
                this.filters.hasComments = defaultFilters.hasComments;
                break;
            case FilterNames.HasAppliedCountermeasures:
                this.filters.hasAppliedCountermeasures = defaultFilters.hasAppliedCountermeasures;
                break;
            case FilterNames.IsPartOfCase:
                this.filters.isPartOfCase = defaultFilters.isPartOfCase;
                break;
            case FilterNames.Sort:
                this.filters.sort = defaultFilters.sort;
                break;
            case FilterNames.Status:
            case FilterNames.Search:
            case FilterNames.Flagged:
                // These are used outside this component, so don't clear them
                break;
            default:
                console.error('Unable to clear unsupported filter name.');
                break;
        }

        this.changeHandlingEnabled = true;
    }

    private searchVessels = (query: string): void => {
        if (!query) {
            this.filteredVessels = this.vessels;
            return;
        }

        this.filteredVessels = this.vessels.filter((v) =>
            v.name.toLowerCase().includes(query.toLowerCase())
            || v.ttSiteId.toString().includes(query)
        );
    };

    private handleVesselSelect = async (option: ComboboxOption): Promise<void> => {
        if (!option || !option.value) {
            this.filters.vessel = null;
            return;
        }

        this.filters.vessel = Number(option.value);
    };

    private setUrlFilterParams(): void {
        const route = 'threats';

        // Only update URL when route is within threats
        if (!window.location.pathname.includes(route))
            return;

        const params = new URLSearchParams();

        if (!DateRangePicker.isRange(this.filters.date, DateRangePicker.defaultRangeLabel)) {
            params.append('from', this.filters.date.from.valueOf().toString());
            params.append('until', this.filters.date.until.valueOf().toString());
        }

        if (this.filters.category !== this.defaultFilters.category)
            params.append('category', this.filters.category);

        if (this.filters.vessel !== this.defaultFilters.vessel)
            params.append('vessel', this.filters.vessel.toString());

        if (this.filters.zone !== this.defaultFilters.zone)
            params.append('zone', this.filters.zone);

        if (this.filters.rule !== this.defaultFilters.rule)
            params.append('rule', this.filters.rule);

        if (this.filters.ttp !== this.defaultFilters.ttp)
            params.append('ttp', this.filters.ttp);

        if (this.filters.channel !== this.defaultFilters.channel)
            params.append('channel', this.filters.channel);

        if (this.filters.lastAction !== this.defaultFilters.lastAction)
            params.append('lastAction', this.filters.lastAction);

        if (this.filters.hasComments !== this.defaultFilters.hasComments)
            params.append('has-comments', this.filters.hasComments.toString());

        if (this.filters.hasAppliedCountermeasures !== this.defaultFilters.hasAppliedCountermeasures)
            params.append('has-applied-countermeasures', this.filters.hasAppliedCountermeasures.toString());

        if (this.filters.isPartOfCase !== this.defaultFilters.isPartOfCase)
            params.append('is-part-of-case', this.filters.isPartOfCase.toString());

        if (this.filters.sort !== this.defaultFilters.sort)
            params.append('sort', this.filters.sort);

        if (this.filters.urgency[0] !== this.defaultFilters.urgency[0]
            || this.filters.urgency[1] !== this.defaultFilters.urgency[1]) {
            params.append('urgency-from', this.filters.urgency[0].toString());
            params.append('urgency-to', this.filters.urgency[1].toString());
        }

        if (this.filters.status !== this.defaultFilters.status)
            params.append('status', this.filters.status);

        if (this.filters.search !== this.defaultFilters.search)
            params.append('search', this.filters.search);

        // The current URI's search params to compare the new params against
        const existingParams = new URLSearchParams(window.location.search);
        const existingParamsSorted = [...existingParams].sort((a, b) => a[0].localeCompare(b[0]));

        // Sort params for consistency and comparing
        const newParamsSorted = [...params].sort((a, b) => a[0].localeCompare(b[0]));

        // Skip routing when the new params are the same as the current URL params
        if (JSON.stringify(existingParamsSorted) === JSON.stringify(newParamsSorted))
            return;

        // Convert params array to a simple params object the router expects
        const routeParams = {};
        for (const param of newParamsSorted)
            routeParams[param[0]] = param[1];

        // Navigate to the route using the created route params, updating the URL
        this.router.navigateToRoute(route, routeParams);
    }
}
